import styled from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const EmptyMessageContainer = styled.div`
  /* stylelint-disable-next-line media-query-no-invalid */
  @media screen and ${breakpoints.desktop.min.sm} {
    display: flex;
    justify-content: center;
    padding: var(--fd-space-space-5);
  }
`;

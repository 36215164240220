import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  useRaceTracks,
  RESULTED_RACES_STATUS,
  useUserPromos
} from "@urp/lib-racetracks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { FilterOptions } from "@urp/lib-racetracks/src/types";
import { hasFiltersApplied } from "@urp/lib-racetracks/src/utils/races";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import { getTracksAZInfo } from "../../redux/selectors";
import TracksAZList from "../TracksAZList";
import { tracksAzInfo } from "../../redux/defaultValues";
import { BASE_QA_LABEL, LIST } from "../../utils/constants";
import { getRaceTracksByLetter } from "../../utils";
import EmptyMessage from "../EmptyMessage";
import TracksSection from "../TracksSection";
import TracksAZLoading from "../../loadingMasks/TracksAZLoading";
import { EmptyMessageContainer } from "./styled-components";

interface TracksAZProps {
  isResults?: boolean;
  filterOptions?: FilterOptions;
}

const TracksAZ = ({ isResults = false, filterOptions }: TracksAZProps) => {
  const {
    title = tracksAzInfo.title,
    resultedTitle = tracksAzInfo.resultedTitle
  } = useSelector(getTracksAZInfo);
  const { tracksRaces, onTrackClickHandler, loading } = useRaceTracks({
    ...filterOptions,
    raceStatusFilter: isResults ? RESULTED_RACES_STATUS : []
  });
  const { checkRacePromos } = useUserPromos(PromotionTypeEnum.RACE);

  if (loading) {
    return <TracksAZLoading />;
  }

  if (!tracksRaces?.length && hasFiltersApplied(filterOptions)) {
    return (
      <TracksSection
        title={title}
        qaLabel={buildQaLabel([BASE_QA_LABEL, LIST])}
      >
        <EmptyMessageContainer>
          <EmptyMessage />
        </EmptyMessageContainer>
      </TracksSection>
    );
  }

  return (
    <TracksAZList
      title={isResults ? resultedTitle : title}
      groupByLetter={getRaceTracksByLetter(tracksRaces)}
      qaLabel={BASE_QA_LABEL}
      onTrackClickHandler={onTrackClickHandler}
      checkRacePromos={checkRacePromos}
    />
  );
};

export default memo(TracksAZ);

import { gql, useSubscription } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { Race } from "@tvg/ts-types/Race";
import { useWagerProfile } from "@tvg/custom-hooks";
import { useSelector } from "react-redux";
import { getAccountNumber } from "@urp/store-selectors";
import mediator from "@tvg/mediator";
import { useEffect, useState } from "react";
import { getHasRequestedLogin } from "@tvg/pp/src/store/selectors";

export const MTP_GLOBAL_UPDATE = gql`
  subscription raceMtpUpdate(
    $wagerProfile: String!
    $noLoggedIn: Boolean!
    $product: String
    $device: String
    $brand: String
  ) {
    featuredTrackAndRaceMtpOrStatusUpdate(profile: $wagerProfile) {
      id
      mtp
      postTime
      track {
        code
        isFavorite @skip(if: $noLoggedIn)
        featured
      }
      highlighted(product: $product, device: $device, brand: $brand) {
        description
        pinnedOrder
        action
        style
      }
      promos(product: $product, brand: $brand) {
        rootParentPromoID
        isAboveTheLine
        promoPath
        isPromoTagShown
      }
      type {
        code
      }
      status {
        code
      }
      video {
        onTvg
        onTvg2
      }
    }
  }
`;

const useMtpUpdate = () => {
  const wagerProfile: string = useWagerProfile();
  const hasRequestedLogin = useSelector(getHasRequestedLogin);
  const accountId = useSelector(getAccountNumber);
  const [loggedIn, setLoggedIn] = useState(!!accountId);
  const [shouldSkip, setShouldSkip] = useState(true);
  useEffect(() => {
    setLoggedIn(!!accountId);
  }, [accountId]);

  useEffect(() => {
    if (wagerProfile && hasRequestedLogin) {
      setShouldSkip(false);
    }
  }, [wagerProfile, hasRequestedLogin]);

  useSubscription(MTP_GLOBAL_UPDATE, {
    variables: {
      wagerProfile,
      noLoggedIn: !loggedIn,
      ...tvgConf().graphContext()
    },
    skip: shouldSkip,
    onData: (result) => {
      const updatedData =
        result?.data?.data?.featuredTrackAndRaceMtpOrStatusUpdate;

      mediator.base.dispatch({
        type: "URP:MTP_UPDATE",
        payload: {
          data: updatedData
        }
      });
      if (
        (updatedData || []).some(
          (raceData: Race) => raceData.status.code === "RO"
        )
      ) {
        mediator.base.dispatch({
          type: "URP:MTP_UPDATE_CLOSED_RACES",
          payload: {}
        });
      }
    }
  });
};

export default useMtpUpdate;

import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  breakpoints,
  Button,
  DialogMessage,
  IconNames,
  IconWithBackground,
  useMediaQuery,
  useTheme
} from "@tvg/design-system";
import { useRaceTracksFilter } from "@urp/lib-racetracks";
import {
  Container,
  IconContainer,
  MessageContainer,
  Text,
  TextContainer,
  Title
} from "./styled-component";
import { getTracksEmptyMessageInfo } from "../../redux/selectors";

interface EmptyMessageProps {
  currentTab?: string;
  isGlobal?: boolean;
  isTracksSearchActive?: boolean;
}

const EmptyMessage: FC<EmptyMessageProps> = ({
  currentTab = "",
  isGlobal = false,
  isTracksSearchActive = false
}) => {
  const { colorTokens } = useTheme();
  const { handleClearFilters } = useRaceTracksFilter();
  const { bySearch, byFilters } = useSelector(getTracksEmptyMessageInfo);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const handleCleanFilter = () => {
    handleClearFilters();
  };

  return (
    <Container isGlobal={isGlobal}>
      {isGlobal ? (
        <DialogMessage
          illustration={
            <IconWithBackground
              mainCircleBackground={`${colorTokens.background.infoSubtle}`}
              mainIcon={{
                name: "tracks",
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
              badgeIcon={{
                name: "info" as IconNames,
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
            />
          }
          title={
            currentTab === "tracks"
              ? bySearch?.tracks.title
              : bySearch?.results.title
          }
          description={
            currentTab === "tracks"
              ? bySearch?.tracks.hint
              : bySearch?.results.hint
          }
          padding="space-7"
        />
      ) : isMobile ? (
        <MessageContainer>
          <IconContainer>
            <IconWithBackground
              mainCircleBackground={`${colorTokens.background.infoSubtle}`}
              mainIcon={{
                name: "filters",
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`,
                size: "m"
              }}
              badgeIcon={{
                name: "info" as IconNames,
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
            />
          </IconContainer>
          <TextContainer>
            <Title data-qa-label="empty-message-title">{byFilters.title}</Title>
            <Text data-qa-label="empty-message-hint">{byFilters.hint}</Text>
          </TextContainer>
        </MessageContainer>
      ) : (
        <DialogMessage
          illustration={
            <IconWithBackground
              mainCircleBackground={`${colorTokens.background.infoSubtle}`}
              mainIcon={{
                name: "filters",
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
              badgeIcon={{
                name: "info" as IconNames,
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
            />
          }
          title={byFilters.title}
          description={byFilters.hint}
          padding="space-7"
        />
      )}
      {!isTracksSearchActive && (
        <Button
          qaLabel="clear-filter-btn"
          variant="primary"
          onPress={handleCleanFilter}
          isStretched
        >
          Clear filters
        </Button>
      )}
    </Container>
  );
};

export default EmptyMessage;

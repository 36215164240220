import React, { FC, useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { InputCheckboxSelect } from "@tvg/design-system/web";
import { Button } from "@tvg/design-system/";
import { TABS_NAMES, useRaceTracksFilter } from "@urp/lib-racetracks";
import { getIsLogged } from "@urp/store-selectors";
import {
  getRaceTracksIsSearchActive,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";
import {
  sendAllFiltersModalOpenedAnalyticEvt,
  sendTracksFilterAnalyticEvt
} from "@urp/lib-racetracks/src/utils/analytics";
import { FilterType, FilterName } from "@urp/lib-racetracks/src/types";
import { FiltersContainer, InputsWrapper, Section } from "./styled-component";
import RaceTracksSearch from "../RaceTracksSearch";
import { COMP_WIDTH_PLUS_ERROR_MARGIN } from "./constants";
import { getMappedProps } from "./utils";
import FilterTags from "../FilterTags";
import type { MappedFilterProps, Props } from "./types";
import Drawer from "./Drawer";
import FilterGroup from "./FilterGroup";
import {
  getFiltersConfiguration,
  getIsAllFiltersFeatureEnabled
} from "../../redux/selectors";
import {
  ACTION,
  CONTAINER,
  CONTENT,
  FILTER,
  SECTION
} from "../../utils/constants";
import FiltersDrawerFooter from "./FiltersDrawerFooter";

const TracksFilter: FC<Props> = ({ currentTab, qaLabel = "", loading }) => {
  const filterContainerRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);
  const [numVisibleFilters, setNumVisibleFilters] = useState(0);
  const isLogged = useSelector(getIsLogged);
  const filtersConfig = useSelector(getFiltersConfiguration);
  const isAllFiltersEnabled = useSelector(getIsAllFiltersFeatureEnabled);
  const currentTabTracksPage = useSelector(getCurrentTabTracksPage);

  const {
    handleRaceTypeChange,
    handleRegionChange,
    handleTrackChange,
    handleDistanceFilter,
    handleRaceFilter,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter,
    handleClearFilters,
    getActiveFiltersBy
  } = useRaceTracksFilter();

  const hasFiltersApplied = useMemo(
    () =>
      [
        ...raceTypesFilter,
        ...regionsFilter,
        ...trackTypesFilter,
        ...racesFilter,
        ...distancesFilter
      ].length > 0,
    [
      raceTypesFilter,
      regionsFilter,
      trackTypesFilter,
      racesFilter,
      distancesFilter
    ]
  );

  /**
   * Will set the amount of visible filters depending on the width of its container
   */
  useEffect(() => {
    if (isAllFiltersEnabled) {
      const element = filterContainerRef.current;
      if (!element) return () => {};

      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const { width } = entry.contentRect;
          const roundedValue = Math.floor(width / COMP_WIDTH_PLUS_ERROR_MARGIN);
          setNumVisibleFilters(roundedValue);
        });
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [filterContainerRef.current?.clientWidth, isAllFiltersEnabled]);

  if (loading) {
    return null;
  }

  const tracksFilters = filtersConfig
    .filter(({ visible }) => visible)
    .reduce<MappedFilterProps[]>((acc, { id, label }) => {
      const filterProps = getMappedProps(id, {
        currentTab,
        handleDistanceFilter,
        handleRaceFilter,
        handleRaceTypeChange,
        handleRegionChange,
        handleTrackChange,
        isLogged,
        distancesFilter,
        racesFilter,
        raceTypesFilter,
        regionsFilter,
        trackTypesFilter,
        qaLabel
      });

      if (filterProps)
        acc.push({
          ...filterProps,
          label
        });

      return acc;
    }, []);

  const inputCheckboxes = tracksFilters.map(
    (
      { onChange, options, qaLabel: mapQaLabel, selectedValue, label },
      index
    ) => (
      <InputCheckboxSelect
        qaLabel={mapQaLabel}
        key={`${label}-${index.toString()}`}
        selectList={options}
        onSelectCheckbox={onChange}
        selectedValues={selectedValue}
        label={label}
      />
    )
  );

  // Cuts down the array of all filters with the state stored
  const renderInputCheckboxFilters = isAllFiltersEnabled
    ? inputCheckboxes.splice(0, numVisibleFilters)
    : inputCheckboxes;

  const renderModalFilters = tracksFilters.map(
    ({
      filterKey,
      label,
      onChange,
      options,
      qaLabel: mapQaLabel,
      selectedValue
    }) => (
      <FilterGroup
        onChange={onChange}
        options={options}
        selectedValues={[...selectedValue]}
        title={label}
        qaLabel={mapQaLabel}
        key={filterKey}
      />
    )
  );

  // If we dont have filters (this stops the button from rendering when the page is loading)
  // or our array of buildInputCheckboxFilters (result of splicing) has something, we should stop the button
  // from rendering
  const areCardsHidden = numVisibleFilters !== 0 && inputCheckboxes.length > 0;

  const onOpenModal = () => {
    sendAllFiltersModalOpenedAnalyticEvt({
      module: "tracks_filters",
      activeTab: currentTabTracksPage
    });
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onClearFilters = () => {
    handleClearFilters();
    onCloseModal();
  };

  const onApplyAllFilters = () => {
    sendTracksFilterAnalyticEvt({
      module: "tracks_filters",
      filterType: FilterType.ALL_FILTERS,
      filterName: getActiveFiltersBy("Filter Name") as FilterName[],
      filterValue: getActiveFiltersBy("Filter Value") as string[]
    });
    onCloseModal();
  };

  return (
    <Section data-qa-label={buildQaLabel([qaLabel, FILTER, SECTION])}>
      <FiltersContainer
        data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER])}
      >
        <RaceTracksSearch
          analyticsEnrichment={{ activeTab: TABS_NAMES.TODAY }}
          qaLabel={buildQaLabel([qaLabel, FILTER])}
        />
        <InputsWrapper
          hidden={isTracksSearchActive}
          ref={filterContainerRef}
          data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER, CONTENT])}
          isAllFiltersEnabled={isAllFiltersEnabled}
        >
          {renderInputCheckboxFilters}
        </InputsWrapper>
        {!isTracksSearchActive && areCardsHidden && isAllFiltersEnabled && (
          <Button
            qaLabel={buildQaLabel([qaLabel, FILTER, ACTION])}
            onPress={onOpenModal}
            variant="secondary"
            icon="filters"
          >
            Filters
          </Button>
        )}
      </FiltersContainer>
      <FilterTags currentTab={currentTab} />
      {isAllFiltersEnabled && (
        <Drawer
          isOpen={isOpen}
          onClose={onCloseModal}
          title="Filter by"
          qaLabel={buildQaLabel([qaLabel, FILTER])}
          footer={
            <FiltersDrawerFooter
              hasFiltersApplied={hasFiltersApplied}
              onApply={onApplyAllFilters}
              onClear={onClearFilters}
            />
          }
        >
          {renderModalFilters}
        </Drawer>
      )}
    </Section>
  );
};

export default TracksFilter;

import {
  OptInPromotion,
  Promo,
  UserEligiblePromotion
} from "@tvg/ts-types/Promos";
import { RaceStatusEnum, RaceTypeCodeEnum } from "@tvg/ts-types/Race";

export enum RegionFiltersEnum {
  US = "USA",
  International = "INT"
}

export type FavoriteWithIdTracks = {
  [code: string]: number;
};

export type Favorite = {
  entityDob?: number | null;
  entityName: string;
  entityType: string;
  favoriteId: number;
  // @TODO: confirm if we need this...
  subscriptions?: FavoriteSubscriptions[];
};

export type FavoriteSubscriptions = {
  eventType?: string;
  channels?: ChannelsSubscriptions[];
};

export type ChannelsSubscriptions = {
  type?: string;
};

export type TracksFilterOption = {
  label: string;
  value: string;
};

export type TracksFilter = {
  title: string;
  options: Array<TracksFilterOption>;
};

export type TracksFilterMsg = {
  raceTypes: TracksFilter;
  regions: TracksFilter;
};

export type TracksSearchMsg = {
  inputPlaceholder: string;
  buttonText: string;
};

export type TabConfig = {
  id: string;
  title: string;
  url: string;
  enabled: boolean;
  trackListLabel?: string;
};

export interface EmptyRaceInfo {
  title?: string;
  description?: string;
}

export type TabsTracks = {
  tabs: TabConfig[];
  tabsDesktop: TabConfig[];
  todaysRacesEmpty: EmptyRaceInfo;
  resultsEmpty: EmptyRaceInfo;
  tracksInformationListEmpty: EmptyRaceInfo;
};

export enum TABS {
  TODAY = "tracks",
  RESULTS = "results",
  ALL_TRACKS = "all-info"
}

export enum TABS_NAMES {
  TODAY = "today",
  RESULTS = "results",
  ALL_TRACKS = "all tracks"
}

export enum FilterType {
  SEARCH = "search",
  FACET = "facet",
  ALL_FILTERS = "all_filters"
}

export type FilterOptions = {
  regionsFilter?: Array<string>;
  raceTypesFilter?: Array<RaceTypeCodeEnum>;
  tracksSearchQuery?: string;
  trackTypesFilter?: TrackFilterValueEnum[];
  racesFilter?: string[];
  distancesFilter?: string[];
  raceStatusFilter?: Array<RaceStatusEnum>;
};

export interface ApolloUserPromotions {
  userPromotions: {
    promotions: Promo[];
    eligiblePromotions: UserEligiblePromotion[];
  } | null;
}

export interface ApolloUserOptinPromotions {
  userOptInPromotions: OptInPromotion[] | null;
}
export type TopTracksConfig = {
  maxResults: number;
};

export enum TrackFilterValueEnum {
  FEATURED = "featured",
  FAVORITE = "favorites"
}

export enum FilterName {
  REGION = "region",
  RACE_TYPE = "race_type",
  TRACK_TYPE = "track_type",
  BETTING_FEATURES = "betting_features",
  DISTANCE = "distance"
}

import { RaceInfoMyBets } from "@tvg/ts-types/Race";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { BetBasicInfo } from "@tvg/ts-types/Bet";
import { Actions, SelectedRace } from "./actions";
import {
  ActiveTabEnum,
  SettledTabEnum,
  StatusFilters,
  MyBetsDynamicFilters
} from "../utils/types";

export interface ActiveBets {
  totalBets: number;
  totalAmount: number;
  bets: WroWagerGroup[];
}

export interface BetCancelModal {
  isOpen?: boolean;
  wager?: WroWager | BetBasicInfo;
  races?: RaceInfoMyBets[];
  isLoading?: boolean;
  result?: {
    status?: "success" | "failure" | "aborted";
    errorCode?: string;
  };
  cancelRequestError?: unknown;
  hasBackgroundModal?: boolean;
  queryError?: null;
}

export interface BetSocialShareModal {
  isOpen?: boolean;
  wager?: WroWager;
  races?: RaceInfoMyBets[];
  isLoading?: boolean;
}

export interface WagerCancellationModal {
  isOpen: boolean;
  hasBack: boolean;
}

export interface TrackRulesModal {
  isOpen: boolean;
  betType: string;
}

export interface State {
  isOpen: boolean;
  activeBets: ActiveBets;
  selectedTab: ActiveTabEnum;
  selectedSettledTab: SettledTabEnum;
  totalActiveBets: number;
  totalSettledBets: number;
  totalFutureBets: number;
  deletedBets: string[];
  isLoading: boolean;
  betCancelModal: BetCancelModal;
  betSocialShareModal: BetSocialShareModal;
  approxPayoutModal: {
    isOpen?: boolean;
  };
  wagerCancellationModal: WagerCancellationModal;
  trackRulesModal: TrackRulesModal;
  pastPerformance: {
    isOpen: boolean;
    selectedRace: SelectedRace | {};
    modalTitle?: string;
  };
  activeWageredAmount: number;
  settledWageredAmount: number;
  futureWageredAmount: number;
  statusFilters: StatusFilters;
  statusFilterCount: number;
  trackFilters: MyBetsDynamicFilters;
  trackFilterCount: number;
  betTypeFilters: MyBetsDynamicFilters;
  betTypeFilterCount: number;
  customStartDate?: Date | null;
  customEndDate: Date | null;
  isCustomTimeFrame: boolean;
  shouldDefaultToSettled: boolean;
  shouldDefaultToFuture: boolean;
  racesActiveBets: Record<number, number>;
}

const initialMyBetsIPP = {
  isOpen: false,
  selectedRace: {},
  modalTitle: "My Bets"
};

export const initialState: State = {
  isOpen: false,
  activeBets: {
    totalBets: 0,
    totalAmount: 0,
    bets: []
  },
  selectedTab: ActiveTabEnum.SETTLED,
  selectedSettledTab: SettledTabEnum.TODAY,
  totalActiveBets: 0,
  totalSettledBets: 0,
  totalFutureBets: 0,
  deletedBets: [],
  isLoading: false,
  betCancelModal: {
    isOpen: false,
    hasBackgroundModal: false
  },
  betSocialShareModal: {
    isOpen: false
  },
  approxPayoutModal: {
    isOpen: false
  },
  wagerCancellationModal: {
    isOpen: false,
    hasBack: false
  },
  trackRulesModal: {
    isOpen: false,
    betType: ""
  },
  pastPerformance: {
    ...initialMyBetsIPP
  },
  activeWageredAmount: 0,
  settledWageredAmount: 0,
  futureWageredAmount: 0,
  statusFilters: {
    Cancelled: false,
    Wins: false,
    Lost: false,
    Refunded: false
  },
  statusFilterCount: 0,
  trackFilters: [],
  trackFilterCount: 0,
  betTypeFilters: [],
  betTypeFilterCount: 0,
  customStartDate: null,
  customEndDate: null,
  isCustomTimeFrame: false,
  shouldDefaultToSettled: true,
  shouldDefaultToFuture: false,
  racesActiveBets: {}
};

export default function reducer(
  // eslint-disable-next-line
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "OPEN_MYBETS_STANDALONE":
      return { ...state, isOpen: true };

    case "CLOSE_MYBETS_STANDALONE":
      return {
        ...state,
        isOpen: false,
        pastPerformance: {
          ...initialMyBetsIPP
        }
      };

    case "SET_ACTIVE_BETS_MYBETS_STANDALONE":
      return {
        ...state,
        activeBets: {
          ...state.activeBets,
          ...action.payload
        }
      };
    case "SET_SELECTED_TAB_MYBETS_STANDALONE":
      return { ...state, selectedTab: action.payload };

    case "SET_SELECTED_SETTLED_TAB_MYBETS_STANDALONE":
      return { ...state, selectedSettledTab: action.payload };

    case "SET_RACES_ACTIVE_BETS_STANDALONE":
      return { ...state, racesActiveBets: action.payload };

    case "SET_COUNTER_MYBETS_STANDALONE":
      if (action.payload === state.totalActiveBets) return state;
      return { ...state, totalActiveBets: action.payload };
    case "SET_SETTLED_COUNTER_MYBETS_STANDALONE":
      if (action.payload === state.totalSettledBets) return state;
      return { ...state, totalSettledBets: action.payload };
    case "SET_ACTIVE_WAGERED_AMOUNT_MYBETS_STANDALONE":
      if (action.payload === state.activeWageredAmount) return state;
      return { ...state, activeWageredAmount: action.payload };
    case "SET_SETTLED_WAGERED_AMOUNT_MYBETS_STANDALONE":
      if (action.payload === state.settledWageredAmount) return state;
      return { ...state, settledWageredAmount: action.payload };
    case "SET_FUTURE_WAGERED_AMOUNT_MYBETS_STANDALONE":
      if (action.payload === state.futureWageredAmount) return state;
      return { ...state, futureWageredAmount: action.payload };
    case "SET_FUTURE_COUNTER_MYBETS_STANDALONE":
      if (action.payload === state.totalFutureBets) return state;
      return { ...state, totalFutureBets: action.payload };
    case "SET_SHOULD_DEFAULT_TO_SETTLED":
      return {
        ...state,
        shouldDefaultToSettled: action.payload.isDefaultSettled,
        selectedTab:
          !state.isOpen && state.totalActiveBets
            ? ActiveTabEnum.ACTIVE
            : state.selectedTab
      };
    case "SET_SHOULD_DEFAULT_TO_FUTURE":
      return {
        ...state,
        shouldDefaultToFuture: action.payload.isDefaultFuture,
        selectedTab:
          !state.isOpen && action.payload.isDefaultFuture
            ? ActiveTabEnum.FUTURES
            : state.selectedTab
      };
    case "MY_BETS_UPDATING_RACES":
      return {
        ...state,
        betCancelModal: {
          ...state.betCancelModal,
          races: action.payload
        }
      };
    case "OPEN_BET_CANCEL_MODAL": {
      const { betCancelModal } = state;

      return {
        ...state,
        betCancelModal: {
          ...betCancelModal,
          ...action.payload,
          isLoading: false,
          isOpen: true
        }
      };
    }
    case "CLOSE_BET_CANCEL_MODAL": {
      const { betCancelModal } = state;
      return {
        ...state,
        betCancelModal: {
          ...betCancelModal,
          ...action.payload,
          isOpen: false
        }
      };
    }

    case "OPEN_BET_SOCIAL_SHARE_MODAL": {
      const { betSocialShareModal } = state;

      return {
        ...state,
        betSocialShareModal: {
          ...betSocialShareModal,
          ...action.payload,
          isLoading: false,
          isOpen: true
        }
      };
    }
    case "CLOSE_BET_SOCIAL_SHARE_MODAL": {
      return {
        ...state,
        betSocialShareModal: {
          isOpen: false
        }
      };
    }
    case "LOADING_BET_CANCEL_MODAL_ON": {
      const { betCancelModal } = state;
      betCancelModal.isLoading = true;
      return {
        ...state,
        betCancelModal
      };
    }
    case "LOADING_BET_CANCEL_MODAL_OFF": {
      const { betCancelModal } = state;
      betCancelModal.isLoading = false;
      return {
        ...state,
        betCancelModal
      };
    }
    case "LOADING_BET_CANCEL_MODAL_ERROR": {
      const { betCancelModal } = state;
      betCancelModal.isLoading = false;
      betCancelModal.cancelRequestError = action.payload;
      return {
        ...state,
        betCancelModal
      };
    }
    case "CLEAN_BET_CANCEL_RESULT": {
      const { betCancelModal } = state;
      betCancelModal.result = {};
      betCancelModal.cancelRequestError = null;
      betCancelModal.queryError = null;
      return {
        ...state,
        betCancelModal
      };
    }
    case "OPEN_APPROX_PAYOUT_MODAL": {
      return {
        ...state,
        approxPayoutModal: {
          isOpen: true
        }
      };
    }
    case "CLOSE_APPROX_PAYOUT_MODAL": {
      return {
        ...state,
        approxPayoutModal: {
          isOpen: false
        }
      };
    }
    case "OPEN_WAGER_CANCELLATION_RULES_MODAL": {
      return {
        ...state,
        wagerCancellationModal: {
          isOpen: true,
          hasBack: action.payload.hasBack
        }
      };
    }
    case "CLOSE_WAGER_CANCELLATION_RULES_MODAL": {
      return {
        ...state,
        wagerCancellationModal: {
          isOpen: false,
          hasBack: false
        }
      };
    }
    case "OPEN_TRACK_RULES_MODAL": {
      return {
        ...state,
        trackRulesModal: {
          isOpen: true,
          betType: action.payload.betType
        }
      };
    }
    case "CLOSE_TRACK_RULES_MODAL": {
      return {
        ...state,
        trackRulesModal: {
          isOpen: false,
          betType: ""
        }
      };
    }
    case "CLOSE_MYBETS_PAST_PERFORMANCE": {
      return {
        ...state,
        pastPerformance: {
          ...initialMyBetsIPP
        }
      };
    }
    case "OPEN_MYBETS_PAST_PERFORMANCE": {
      return {
        ...state,
        pastPerformance: {
          ...state.pastPerformance,
          isOpen: true,
          selectedRace: action.payload.selectedRace,
          modalTitle: action.payload.modalTitle || initialMyBetsIPP.modalTitle
        }
      };
    }
    case "IS_LOADING_MYBETS": {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }
    default: {
      return state;
    }
    case "SELECT_MYBETS_STATUS_FILTERS": {
      return {
        ...state,
        statusFilters: action.payload.statusFilter
      };
    }
    case "CLEAR_MYBETS_STATUS_FILTERS": {
      return {
        ...state,
        statusFilters: initialState.statusFilters
      };
    }
    case "SET_MYBETS_STATUS_FILTER_COUNT": {
      return {
        ...state,
        statusFilterCount: action.payload.count
      };
    }

    case "SELECT_MYBETS_TRACKS_FILTERS": {
      return {
        ...state,
        trackFilters: action.payload.trackFilter
      };
    }
    case "SET_MYBETS_TRACKS_FILTER_COUNT": {
      return {
        ...state,
        trackFilterCount: action.payload.count
      };
    }
    case "SET_MYBETS_BET_TYPE_FILTERS": {
      return {
        ...state,
        betTypeFilters: action.payload.betTypeFilter
      };
    }
    case "SET_MYBETS_BET_TYPE_FILTER_COUNT": {
      return {
        ...state,
        betTypeFilterCount: action.payload.count
      };
    }
    case "SET_MYBETS_CUSTOM_END_DATE": {
      return {
        ...state,
        customEndDate: action.payload.date
      };
    }
    case "SET_MYBETS_CUSTOM_START_DATE": {
      return {
        ...state,
        customStartDate: action.payload.date
      };
    }
    case "SET_MYBETS_IS_CUSTOM_TIMEFRAME": {
      return {
        ...state,
        isCustomTimeFrame: action.payload.isCustomTimeFrame
      };
    }
  }
}

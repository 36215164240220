import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  ProgramPageData,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.URP_RUNNER_SELECTION,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.URP_RUNNER_SELECTION, {
        [BaseEventProps.EVENT_CATEGORY]: "Selection",
        [ModuleEventProps.PLACE_TYPE]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: "Race Card",
        [BaseEventProps.RACE_TRACK_SELECTION]: get(
          data,
          "payload.race.track.trackName"
        ),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.race.raceNumber"
        ),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.RUNNER_SELECTION,
    (data: ProgramPageData) => {
      track<EventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [ModuleEventProps.PLACE_TYPE]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.module"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(
          data,
          "payload.race.track.trackName"
        ),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.race.raceNumber"
        ),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SELECT_ALL_RUNNERS,
    (data: ProgramPageData) => {
      track<EventProps>(
        data.payload.add ? "Add To Betslip" : "Remove Selection From Betslip",
        {
          [BaseEventProps.EVENT_CATEGORY]: "Bet",
          ...(data.payload.add
            ? { [ModuleEventProps.PLACE_TYPE]: "All Runners" }
            : { [BaseEventProps.EVENT_LABEL]: "All Runners" }),
          [BaseEventProps.MODULE]: "Race Card",
          [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.tag"),
          [BaseEventProps.RACE_TRACK_SELECTION]: get(
            data,
            "payload.race.track.trackName"
          ),
          [BaseEventProps.RACE_NUMBER_SELECTION]: get(
            data,
            "payload.race.raceNumber"
          ),
          [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.RESET_ALL_RUNNERS,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.RESET_ALL_RUNNERS, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.EVENT_LABEL]: "Reset",
        [BaseEventProps.MODULE]: "Race Card",
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.tag"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.race.raceNumber"
        ),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SELECT_BET_TYPE,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.SELECT_BET_TYPE, {
        [BaseEventProps.EVENT_CATEGORY]: "bet selector",
        [BaseEventProps.EVENT_LABEL]: data.payload?.betType,
        [BaseEventProps.MODULE]: "bet_selector",
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload?.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload?.trackName
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SET_BET_AMOUNT,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.BETSLIP_BET_AMOUNT_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: "bet_ticket"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.BET_TIMEOUT,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.BET_TIMEOUT, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.MODULE]: "Confirm Bet Modal",
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.race.raceNumber"
        ),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
        [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.BET_NOW,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.BET_NOW, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.MODULE]: "bet_ticket",
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.race.raceNumber"
        ),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.DELETE_BET_TICKET, () => {
    track<EventProps>(EVENT_NAME.DELETE_BET_TICKET, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: "bet_ticket"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.OPEN_RACE_NAVIGATION,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.OPEN_RACE_NAVIGATION, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: `Race Modal - ${get(
          data,
          "payload.trackName"
        )} - R${get(data, "payload.raceNumber")}`,
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.HANDICAP_STORE_PRODUCT,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.HANDICAP_STORE_PRODUCT, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.product"),
        [BaseEventProps.MODULE]: "PP Trackmaster Picks"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROGRAM_RUNNER_INFO_MODAL_OPENED,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.PROGRAM_RUNNER_INFO_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.RACE_NUMBER]: get(data, "raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "trackName"),
        [BaseEventProps.RUNNER_NAME]: get(data, "runnerName"),
        [BaseEventProps.RUNNER_TAGS_VISIBLE]: get(data, "runnerTagsVisible"),
        [BaseEventProps.MODULE]: "Runner Call Outs"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROGRAM_RUNNER_INFO_MODAL_CLOSED,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.PROGRAM_RUNNER_INFO_MODAL_CLOSED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.RACE_NUMBER]: get(data, "raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "trackName"),
        [BaseEventProps.RUNNER_NAME]: get(data, "runnerName"),
        [BaseEventProps.RUNNER_TAGS_VISIBLE]: get(data, "runnerTagsVisible"),
        [BaseEventProps.MODULE]: "Runner Call Outs"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.HANDICAP_STORE_SEE_ALL,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.HANDICAP_STORE_SEE_ALL, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "see all products",
        [BaseEventProps.MODULE]: "PP Trackmaster Picks",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.RUNNER_MODIFIER_FILTER_APPLIED,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.RUNNER_MODIFIER_FILTER_APPLIED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.filter"),
        [BaseEventProps.MODULE]: "Runner Modifier",
        [ModuleEventProps.FILTER_TYPE]: "Runner Modifier",
        [BaseEventProps.FILTER_VALUE]: get(data, "payload.filter"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.RUNNER_MODIFIER_SORT_APPLIED,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.RUNNER_MODIFIER_SORT_APPLIED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.sortOperation"),
        [BaseEventProps.MODULE]: "Runner Modifier",
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [ModuleEventProps.SORT_TYPE]: "Runner Modifier",
        [ModuleEventProps.SORT_NAME]: get(data, "payload.sortValue"),
        [ModuleEventProps.SORT_OPERATION]: get(data, "payload.sortOperation")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WILL_PAYS_SELECT_OPTION,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.WILL_PAYS_SELECT_OPTION, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: "Will Pays",
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.race.raceNumber")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROBABLES_SELECT_OPTION,
    (data: ProgramPageData) => {
      track<EventProps>(EVENT_NAME.PROBABLES_SELECT_OPTION, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: "Probables",
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.race.raceNumber")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.FAVORITE_INTERACTION,
    (data: ProgramPageData) =>
      track<EventProps>(
        get(data, "payload.isFavorite")
          ? EVENT_NAME.FAVORITED
          : EVENT_NAME.UNFAVORITED,
        {
          [BaseEventProps.MODULE]: "program_page"
        }
      )
  );

  mediator.base.subscribe(MediatorEventType.POOLS_AND_INFO_MODAL_OPENED, () => {
    track<EventProps>(EVENT_NAME.POOLS_AND_INFO_MODAL_OPENED, {
      [BaseEventProps.MODULE]: "program_page"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.POOLS_AND_INFO_TAB_OPENED,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.POOLS_AND_INFO_TAB_OPENED, {
        [BaseEventProps.MODULE]: "pools_and_info",
        [BaseEventProps.TAB]: get(data, "payload.label"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
      })
  );

  mediator.base.subscribe(
    MediatorEventType.HANDICAPPING_TAB_OPENED,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.HANDICAPPING_TAB_OPENED, {
        [BaseEventProps.MODULE]: "program_handicapping",
        [BaseEventProps.TAB]: get(data, "payload.label"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
      })
  );

  mediator.base.subscribe(
    MediatorEventType.RUNNER_TAGS_EXPANDED,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.RUNNER_TAGS_EXPANDED, {
        [BaseEventProps.MODULE]: "runner_details",
        [BaseEventProps.RUNNER_NAME]: get(data, "payload.runnerName"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
      })
  );

  mediator.base.subscribe(
    MediatorEventType.PROGRAM_FREE_PP_DOWNLOAD,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.PROGRAM_FREE_PP_DOWNLOAD, {
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_TYPE]: get(data, "payload.raceType")
      })
  );

  mediator.base.subscribe(
    MediatorEventType.DROPDOWN_OPEN,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.DROPDOWN_OPENED, {
        [BaseEventProps.MODULE]: "race_selector",
        [BaseEventProps.TRACK_NAME]: data.payload.trackName,
        [BaseEventProps.RACE_NUMBER]: data.payload.raceNumber
      })
  );

  mediator.base.subscribe(
    MediatorEventType.DROPDOWN_RACE_SELECTED,
    (data: ProgramPageData) =>
      track<EventProps>(EVENT_NAME.DROPDOWN_RACE_SELECTED, {
        [BaseEventProps.MODULE]: "race_selector_dropdown",
        [BaseEventProps.TRACK_NAME]: data.payload.trackName,
        [BaseEventProps.RACE_NUMBER]: data.payload.raceNumber
      })
  );
};

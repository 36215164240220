import { Dispatch } from "redux";
import { Location, History } from "history";
import { UnaryFn, BinaryFn, NullaryFn } from "@tvg/ts-types/Functional";
import {
  RaceInfoMyBets,
  RaceStatusEnum,
  BettingInterest,
  PickBetFavorite,
  RaceProgram
} from "@tvg/ts-types/Race";
import { BetSelection, BetBasicInfo, BetStatus } from "@tvg/ts-types/Bet";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { Selections } from "@tvg/ts-types/Selections";
import { NavigationLayoutType } from "@tvg/ts-types/Generic";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

export enum ActiveTabEnum {
  ACTIVE = "ACTIVE",
  SETTLED = "SETTLED",
  FUTURES = "FUTURES"
}

export enum SettledTabEnum {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH"
}

export interface MyBetsDynamicFilter {
  name: string;
  code: string;
  isActive: boolean;
}

export type MyBetsDynamicFilters = MyBetsDynamicFilter[];

export interface QueryTrackFilter {
  name: string;
  code: string;
}

export interface QueryBetTypeFilter {
  id: number;
  name: string;
  code: string;
}

export interface GraphWagerType {
  type: {
    code: string;
  };
  isKey: boolean;
  isBox: boolean;
  isWheel: boolean;
  legCount: number;
  positionCount: number;
  columnCount: number;
}

export interface GraphResponse {
  raceDate: string;
  wagerTypes: GraphWagerType[];
  races: RaceInfoMyBets[];
  subscribeToMore: UnaryFn<unknown, void>;
  loading: boolean;
  networkStatus: number;
  refetch: UnaryFn<unknown, void>;
}

export interface ActiveBetsInfoType {
  bets?: WroWagerGroup[];
  totalBets?: number;
  totalAmount?: number;
}

export interface BetCancelResult {
  status?: "success" | "failure" | "aborted";
  errorCode?: string;
}
export interface OnBetCancelClosePayload {
  result?: BetCancelResult;
}

export interface StatusFilters {
  Cancelled: boolean;
  Wins: boolean;
  Refunded: boolean;
  Lost: boolean;
}

export interface BetCancelRedux {
  betAmount?: number;
  betsNumber?: number;
  bettingInterests?: number[];
  cancelRequestError?: unknown;
  dateDiff?: number;
  index?: number;
  isGreyhound?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  mtp?: number;
  queryError?: unknown;
  raceNumber?: string;
  racePostTime?: string;
  result?: BetCancelResult;
  statusCode?: RaceStatusEnum;
  trackCode?: string;
  trackName?: string;
  wager?: WroWager;
  winningsAmount?: number;
}

interface RulesMessage {
  text: string;
  title?: string;
}

export interface ExternalProps {
  behgClient?: ApolloClient<NormalizedCacheObject>;
  isDesktop: boolean;
  accountNumber: string;
}

export interface Props {
  dispatch: Dispatch;
  location: Location;
  history: History;
  device: "mobile" | "tablet" | "desktop";
  isLogged: boolean;
  accountNumber: string;
  behgClient?: ApolloClient<NormalizedCacheObject>;
  fcpClient?: ApolloClient<NormalizedCacheObject>;
  totalActiveBets: number;
  totalFutureBets: number;
  totalSettledBets: number;
  activeWageredAmount: number;
  futureWageredAmount: number;
  settledWageredAmount: number;
  selectedTab: ActiveTabEnum;
  selectedSettledTab: SettledTabEnum;
  // Graph types
  isLoadingGraph: boolean;
  subscribeToMore: UnaryFn<unknown, void>;
  subscribeToMoreGraph: UnaryFn<unknown, void>;
  queryBets: ActiveBetsInfoType;
  races: RaceInfoMyBets[];
  // Conditional for dates fix in IOS
  betCancel: BetCancelRedux;
  raceDate: string;
  isMyBetsModalOpen: boolean;
  wagerCancellationModal: {
    isOpen: boolean;
    hasBack: boolean;
  };
  trackRulesModal: {
    isOpen: boolean;
    betType: string;
  };
  userLocation: string;
  cancelRulesMessages: Record<string, Array<RulesMessage>>;
  cancelLimitationsByState?: string;
  // Race Results
  trackRulesMessages: string;
  fetchMore: BinaryFn<UnaryFn<boolean, void>, number, void>;
  isLoadingBehg: boolean;
  myBetsPaginationConf: {
    wagersPerPage: number;
    intersectionWagerPercentage: number;
  };
  modalScrollableRef: HTMLDivElement;
  isMyBetsPastPerformanceOpen: boolean;
  isLoading: boolean;
  isLoadingActiveTotals: boolean;
  isLoadingSettledTotals: boolean;
  scrollYPosition: number;
  customStartDate?: string;
  customEndDate?: string;
  statusFilters?: StatusFilters;
  refetchSettled?: NullaryFn<void>;
  trackFilters?: MyBetsDynamicFilters;
  betTypeFilters?: MyBetsDynamicFilters;
  wagerProfile: string;
  isCustomTimeFrame?: boolean;
  isDesktop?: boolean;
  redirectWithoutActiveBetsToggle?: boolean;
  isDefaultSettled: boolean;
  isDefaultFuture: boolean;
  statusFilterCount: number;
  trackFilterCount: number;
  betTypeFilterCount: number;
  queryTrackList: Array<QueryTrackFilter & QueryBetTypeFilter>;
  wagerTypeFilter: Array<QueryTrackFilter & QueryBetTypeFilter>;
}

export interface BetCanceDataCommonInfo {
  trackCode: string;
  trackName: string;
  raceNumber: number;
  isGreyhound: boolean;
  racePostTime: string;
  mtp: number;
  statusCode: RaceStatusEnum;
  betsNumber: number;
  betAmount: number;
  dateDiff: number;
  winningsAmount: number;
  bettingInterests: BettingInterest[][];
}

export interface OnBetCancelPayload {
  bet: WroWagerGroup;
  wager: WroWager;
  hasBackgroundModal?: boolean;
}

export interface OnRacePageBetCancelPayload {
  bet: { value: string; wagers: Array<BetBasicInfo> };
  wager: BetBasicInfo;
}

export type OnCancelFunction = UnaryFn<OnBetCancelPayload, void>;

export interface SafeJSONParseArg<T = Object> {
  defaultValue: T;
  jsonToParse: string;
}

export interface StartTimerArgs {
  time: number;
  fn: NullaryFn<void>;
}

export interface MyBetsBaseGtm {
  selectedTab?: string;
  selectedSettledTab?: string;
  show?: boolean;
  activeBetsCount?: number;
  settledBetsCount?: number;
  futureBetsCount?: number;
  previousSelectedTab?: string;
}

export interface OnWatchReplayEvent {
  trackName: string;
  raceNumber: number;
  index: number;
}

export type BetComboHash = Record<string, number>;

export type ProbableValueList = number[];

export interface PotentialReturn {
  betSelections: string[][];
  betCombosHash: Record<string, number>;
  minWagerAmount: number;
  betAmount: number;
  wagerTypeId?: number;
}

export interface CheckRaceStatus {
  isAllRunnersScratched: boolean;
  legContainsScratch: boolean;
  isMultiRace: boolean;
  selectionIndex: number;
  selection: BetSelection[] | Selections[];
  races: RaceInfoMyBets[];
  raceNumber: number;
  selectionLength: number;
  isCanceled: boolean;
  betStatusName?: string;
  wagerType: string;
  favoriteRunner?: PickBetFavorite;
  shouldShowReplacement: boolean;
}

export interface CheckSelectionLabel {
  raceNumber: number;
  selectionLength: number;
  selectionIndex: number;
  selectionsModifier?: string;
  isMultiRace: boolean;
  isKey?: boolean;
  isBox?: boolean;
  isDesktop?: boolean;
}

export type MyBetsCounterType = { counter: number; amount: number };

type FooterLinkType = {
  name: string;
  href: string;
  onClick?: NullaryFn<unknown>;
};

export interface FooterProps {
  dispatch: Dispatch;
  isLogged: boolean;
  accountNumber: string;
  totalActiveBets: number;
  links: FooterLinkType[];
  activePath: string;
  layout: NavigationLayoutType;
  behgClient: ApolloClient<NormalizedCacheObject>;
  subscribeToMore: UnaryFn<unknown, void>;
  selectedTab: ActiveTabEnum;
  selectedSettledTab: SettledTabEnum;
  subscribeToMoreSettled: UnaryFn<unknown, void>;
  refetchSettled: NullaryFn<void>;
  activeBetsCounter: number;
  isCustomTimeFrame?: boolean;
  customStartDate?: Date;
  customEndDate?: Date;
}

export interface SelectedFilters {
  statusFilters?: StatusFilters;
  trackFilters?: MyBetsDynamicFilters;
  betTypeFilters?: MyBetsDynamicFilters;
}

export type OnBetSocialSharePayload = {
  bet: WroWagerGroup;
  wager: WroWager;
  currentRace?: RaceProgram;
  repeatButtonSearch?: string;
  isMyBets?: boolean;
};

export interface WagerGroup {
  betStatus: BetStatus;
  tvgRaceId: number;
  trackCode?: string;
}
export interface WagerHistory {
  id: string;
  futureWagersList: {
    totals: {
      totalCount: number;
      totalAmount: number;
    };
  };
  totals: {
    totalBets: number;
    totalAmount: number;
    totalGambledCount: number;
    totalGambledAmount: number;
  };
  groupWagers?: {
    wagers: WagerGroup[];
  }[];
}

export interface WagerHistoryProps {
  wagerHistory: WagerHistory;
}

export interface BetsType {
  totalActiveBets?: number;
  totalFutureBets?: number;
  activeWageredAmount?: number;
  futureWageredAmount?: number;
}

export type XsellBlacklistedBets = {
  blacklistedBets: [WagerTypeCodesEnum];
};

import { useQuery } from "@apollo/client";
import useWagerProfile from "@tvg/custom-hooks/hooks/useWagerProfile";
import { Race } from "@tvg/ts-types/Race";
import { get } from "lodash";
import { useParams } from "react-router";
// @ts-ignore wait for react router update
import { useSearchParams } from "react-router-dom";
import RaceProgramQuery from "../graphql/queries/RaceProgramQuery";
import queryHelper from "../graphql/options.graph";

const raceClosedStates = ["SK", "MC", "RO", "C"];

const buildWagerPadLink = (
  data: { currentRaces?: Race[]; nextRaces?: Race[] },
  raceNumber: string | null,
  refetch: () => void
) => {
  let race: Race | undefined;

  if (get(data, "currentRaces.length") && raceNumber) {
    // search for current race
    const foundRace: Race | undefined = get(data, "currentRaces", []).find(
      (currentRace: Race) => raceNumber === currentRace.number
    );
    // use current race
    if (foundRace && raceClosedStates.indexOf(foundRace.status.code) < 0) {
      race = foundRace;
      // search for open race on current track
    } else {
      race = get(data, "currentRaces", []).find(
        (currentRace: Race) =>
          raceClosedStates.indexOf(currentRace.status.code) < 0
      );
    }
  }

  // set 1st race available
  if (!race && get(data, "nextRaces.length")) {
    race = get(data, "nextRaces", []).find(
      (currentRace: Race) =>
        raceClosedStates.indexOf(currentRace.status.code) < 0
    );
    if (get(data, "nextRaces.length") && !race) {
      refetch();
    }
  }

  // set link
  if (race) {
    return `/betslip/${race?.track?.code}/${race?.track?.perfAbbr}/${race.number}`;
  }
  // disable button if no race available
  return "";
};

const useBuildRaceLink = (): string => {
  const wagerProfile: string = useWagerProfile();
  const params = useParams<{ trackAbbr: string | undefined }>();
  const [searchParams] = useSearchParams();
  const raceNumber = searchParams.get("race");
  const { data, refetch } = useQuery(
    RaceProgramQuery,
    queryHelper.apolloOptions({ track: params.trackAbbr || "", wagerProfile })
  );

  return buildWagerPadLink(data, raceNumber, refetch);
};

export default useBuildRaceLink;

import { BaseEventProps } from "../../types";
import { Module } from "../raceCell/types";

export enum ModuleEventProps {
  FILTER_TYPE = "Filter Type",
  TAB_NAME = "Tab Name"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  TRACK_CLICK = "TRACKS_TRACK_CLICK",
  RACE_NAVIGATION = "RACE_NAVIGATION",
  FILTER_CLICK = "TRACKS_FILTER_CLICK",
  TAB_OPENED = "TRACKS_TAB_OPENED",
  FILTER_APPLIED = "TRACKS_FILTER_APPLIED",
  SEARCH_APPLIED = "TRACKS_SEARCH_APPLIED",
  TRACKS_FAVORITE = "TRACKS_FAVORITE",
  ALL_FILTERS_MODAL_OPENED = "TRACKS_ALL_FILTERS_MODAL_OPENED"
}

export interface TrackClickData {
  type: MediatorEventType.TRACK_CLICK;
  payload: {
    isOpen: boolean;
    trackName: string;
    tag: string;
    module: string;
    isGreyhound?: boolean;
  };
}

export interface RaceNavigationData {
  type: MediatorEventType.RACE_NAVIGATION;
  payload: {
    trackName: string;
    raceNumber: string;
    mtp: number;
    module: string;
    url: string;
  };
}

export interface FilterClickData {
  type: MediatorEventType.FILTER_CLICK;
  payload: {
    filter: string;
    numRacesUpNext?: number;
    numRacesResultUpNext?: number;
    numRacesFavorites?: number;
    numRacesResultFavorites?: number;
    numRacesAZ?: number;
    numRacesResultAZ?: number;
    isSelecting?: boolean;
  };
}

export interface TabOpenedData {
  type: MediatorEventType.TAB_OPENED;
  payload: {
    tabName: string;
    url: string;
  };
}

export interface FilterAppliedData {
  type: MediatorEventType.FILTER_APPLIED;
  payload: {
    filterName: "race_type" | "region";
    filterValue: string;
    activeTab: string;
  };
}

export interface SearchAppliedData {
  type: MediatorEventType.SEARCH_APPLIED;
  payload: { activeTab: string };
}

export interface FavoriteData {
  type: MediatorEventType.TRACKS_FAVORITE;
  payload: {
    isFavoriting: boolean;
    module: Module;
    trackName: string;
    activeTab: string;
  };
}

export interface FiltersModalOpenedData {
  type: MediatorEventType.ALL_FILTERS_MODAL_OPENED;
  payload: {
    module: string;
    activeTab: string;
  };
}

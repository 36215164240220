import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import HDR from "@tvg/hdr";
import LHN from "@tvg/lhn";
import Footer from "@tvg/footer";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import SeoScreens from "@tvg/seo-desktop";
import ToastManager from "@tvg/toast-manager";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";
import { bannerProps } from "@tvg/sh-utils/fixedBanner";
import useLoadAngular from "@tvg/app-lazy-loader";
import {
  Container,
  Main,
  TVG4Content,
  ContentContainer
} from "./styled-components";
import useMtpUpdate from "../../hooks/useMtpUpdate";
import NavigationTabs from "./components/NavigationTabs";

interface Props {
  isHeaderVisible?: boolean;
  isLHNVisible?: boolean;
  showTVG4Content?: boolean;
  hasMobileView?: boolean;
  tvg4Addon?: JSX.Element;
  requireNavigationTab?: boolean;
}

const MainLayout: React.FC<Props> = ({
  isHeaderVisible = true,
  isLHNVisible = true,
  showTVG4Content = true,
  hasMobileView = false,
  tvg4Addon = null,
  children,
  requireNavigationTab = false
}) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinXxl = useMediaQuery(breakpoints.desktop.min.xxl);
  const [isLargeContent, setIsLargeContent] = useState(false);
  const isFullPageLayout =
    !isHeaderVisible && !isLHNVisible && !showTVG4Content;
  const geolocationStatus = useSelector(getGeolocationStatus);
  const geolocationBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.geoLocationBannerMsg")
  );
  const accountCompliantBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.accountCompliantBannerMsg")
  );
  const isVerified = useSelector((store) => get(store, "userData.isVerified"));
  const isLogged = useSelector((store) => get(store, "userData.logged"));
  const isTermsAccepted = useSelector((store) =>
    get(store, "userData.jurisdiction.accepted")
  );
  const dispatch = useDispatch();
  // GLOBAL UPDATE FOR RACES WITH LESS THEN 60 minutes
  useMtpUpdate();
  useLoadAngular();

  const bannerPropsValues = bannerProps(
    dispatch,
    {
      isVerified,
      isLogged,
      isTermsAccepted
    },
    accountCompliantBannerMsg,
    geolocationBannerMsg,
    geolocationStatus
  );

  return (
    <>
      <Container isFullPageLayout={isFullPageLayout}>
        {isHeaderVisible && <HDR />}
        <Main
          isFullPageLayout={isFullPageLayout}
          hasFixedBanner={!!bannerPropsValues}
        >
          {isLHNVisible &&
            (isDesktopMinXl || typeof window === "undefined") && (
              <LHN
                isInsideHamburgerMenu={false}
                changeCollapsedMediaQuery={!isDesktopMinXxl}
                isHeaderVisible={isHeaderVisible}
                isPositionFixed={true}
                showCollapseButton
                setIsLargeContent={setIsLargeContent}
              />
            )}
          <ContentContainer
            isLHNVisible={isLHNVisible}
            isFullPageLayout={isFullPageLayout}
            isLargeContent={isLargeContent}
            hasMinWidth={!hasMobileView}
          >
            <>
              {requireNavigationTab && <NavigationTabs />}
              <ToastManager
                isLhnVisible={
                  isLHNVisible &&
                  (isDesktopMinXl || typeof window === "undefined")
                }
              />
              <div>
                {tvg4Addon}
                <TVG4Content id="tvg4" showTVG4Content={showTVG4Content} />
              </div>
              {children}
              <SeoScreens />
              {!isFullPageLayout && isDesktop && <Footer />}
            </>
          </ContentContainer>
        </Main>
      </Container>
    </>
  );
};

export default MainLayout;

import React, { PropsWithChildren, useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalHeader } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import {
  Container,
  Content,
  LefHeaderEmpty,
  Overlay,
  Panel
} from "./styled-components";
import { Props } from "./types";
import {
  COLLAPSIBLE,
  CONTAINER,
  CONTENT,
  DRAWER,
  OVERLAY,
  TITLE
} from "../../../utils/constants";

export const Drawer = ({
  isOpen,
  children,
  qaLabel = "",
  title = "",
  footer,
  onClose
}: PropsWithChildren<Props>) => {
  const appContainer = document.getElementById("app");

  if (!document || !appContainer) return null;

  useEffect(() => {
    appContainer.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      appContainer.style.overflow = "auto";
    };
  }, [isOpen]);

  return createPortal(
    <Container
      isOpen={isOpen}
      data-qa-label={buildQaLabel([qaLabel, DRAWER, CONTAINER])}
    >
      <Overlay
        onClick={onClose}
        data-qa-label={buildQaLabel([qaLabel, DRAWER, OVERLAY])}
      />
      <Panel
        isOpen={isOpen}
        data-qa-label={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE])}
      >
        {title && (
          <ModalHeader
            title={title}
            onClose={onClose}
            qaLabel={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE, TITLE])}
            headerLeftElement={<LefHeaderEmpty />}
          />
        )}
        <Content
          data-qa-label={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE, CONTENT])}
        >
          {children}
        </Content>
        {footer}
      </Panel>
    </Container>,
    appContainer
  );
};

export default Drawer;

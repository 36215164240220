import React, {
  useCallback,
  useRef,
  useEffect,
  RefObject,
  MutableRefObject
} from "react";
import { useTransition, config, animated } from "@react-spring/web";
import useOnClickOutside from "@tvg/design-system/src/hooks/useOnClickOutside";
import { Button, Paragraph } from "@tvg/design-system";
import { Withdrawal } from "@tvg/ts-types/Withdrawal";
import withdrawals from "@tvg/withdrawals/src/service";
import { setWithdrawals } from "@tvg/withdrawals/src/actions";
import { getWithdrawals } from "@tvg/withdrawals/src/selectors";
import { isTvg5 } from "@tvg/utils/generalUtils";
import mediator from "@tvg/mediator";
import {
  trackBetaFeatureFeedback,
  events as AlchemerEvents
} from "@urp/alchemer";
import {
  AWPath,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { toggleQuickWithdraw } from "@tvg/sh-lib-paws/redux/slices/quickWithdrawSlice";

// remove after types update for v6
// @ts-ignore
import { useNavigate, useLocation } from "react-router-dom";
import { GestureResponderEvent } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import { isTransactionHistoryModalOpen } from "@urp/transaction-history/src/redux/selectors";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { getAccountNumber } from "@urp/store-selectors";
import {
  MainContainer,
  ContentContainer,
  LogOutContainer
} from "./styled-components";
import AccountHeader from "../MyAccountUserSection";
import AccountLinks from "../MyAccountLinkSection";

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

const AccountMenu: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const ref: MutableRefObject<HTMLDivElement | undefined> = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accountNumber: string = useSelector(getAccountNumber);
  const pendingWithdrawals: Withdrawal[] = useSelector(getWithdrawals);
  const isAWFlowEnabled = useSelector(isUserInAWFlows);
  const isTransactionHistoryModalOpened = useSelector(
    isTransactionHistoryModalOpen
  );
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  useEffect(() => {
    if (isOpen && !isAWFlowEnabled) {
      withdrawals
        .getWithdrawals(accountNumber)
        .then((wd: Withdrawal[]) => dispatch(setWithdrawals(wd)))
        .catch(() => {
          console.log("error fetching withdrawals");
        });
    }
  }, [isOpen]);

  useOnClickOutside(ref as RefObject<HTMLElement>, () => {
    if (!isTransactionHistoryModalOpened) setIsOpen(!isOpen);
  });

  const goTo = (
    route: string,
    target: string = "",
    event?: GestureResponderEvent,
    description: string = ""
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setIsOpen(!isOpen);
    if (target === "_blank" && typeof window !== "undefined") {
      window.open(route, "_blank");
    } else if (route.startsWith("/")) {
      if (isAccountCompliant) {
        navigate(route);
      } else {
        onOpenComplianceModal();
      }
    } else if (typeof window !== "undefined") {
      if (description === "Send Feedback" && isTvg5()) {
        AlchemerEvents.showSendFeedbackSurvey();
        trackBetaFeatureFeedback();
      } else {
        window.location.href = route;
      }
    }
  };

  const callLogout = useCallback(() => {
    setIsOpen(!isOpen);
    mediator.base.dispatch({
      type: "TVG_LOGIN:DO_LOGOUT"
    });
    if (isTvg5()) {
      navigate("/");
    }
  }, []);

  const openQuickDeposit = useCallback(() => {
    if (isAWFlowEnabled) {
      openAWPage(AWPath.Deposit, { forceWeb: true });
    } else {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Account Panel Link Clicked",
          link: "deposit",
          destinationUrl: "#quick-deposit",
          module: "Account Panel"
        }
      });
      setIsOpen(!isOpen);
      dispatch(toggleQuickDepositModal(true));
    }
  }, [isOpen, setIsOpen]);

  const openQuickWithdrawal = useCallback(() => {
    if (isAWFlowEnabled) {
      openAWPage(AWPath.Withdrawals, { forceWeb: true });
    } else {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Account Panel Link Clicked",
          link: "withdraw",
          destinationUrl: "#quick-withdraw",
          module: "Account Panel"
        }
      });
      setIsOpen(!isOpen);
      dispatch(toggleQuickWithdraw(true));
    }
  }, [isOpen, setIsOpen]);

  const openWallet = useCallback(
    (event?: GestureResponderEvent) => {
      if (isAWFlowEnabled) {
        openAWPage(AWPath.Account, { forceWeb: true });
      } else {
        mediator.base.dispatch({
          type: "NAVIGATION:MENU_CLICK",
          payload: {
            action: "Account Panel Link Clicked",
            link: "wallet",
            destinationUrl: "/wallet",
            module: "Account Panel"
          }
        });
        setIsOpen(!isOpen);
        AlchemerEvents.selectWallet();
        goTo("/wallet", "", event);
      }
    },
    [isOpen, setIsOpen]
  );

  const openPendingWithdrawals = useCallback(() => {
    setIsOpen(!isOpen);
    navigate(`${location.pathname}${location.search}#pending-withdrawals`);
  }, [isOpen, setIsOpen]);

  const AnimatedContent = animated(ContentContainer);
  const menuTransition = useTransition(isOpen, {
    config: isOpen ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` }
  });

  return menuTransition(
    (styles, isMenuOpen) =>
      isMenuOpen && (
        <AnimatedContent data-qa-label="myAccountMenu" style={styles}>
          <MainContainer ref={ref as MutableRefObject<HTMLDivElement>}>
            <AccountHeader
              variant="light"
              withdrawOnClick={openQuickWithdrawal}
              depositOnClick={openQuickDeposit}
              walletOnClick={openWallet}
            />
            <AccountLinks
              goTo={goTo}
              openPendingWithdrawals={openPendingWithdrawals}
              withdrawals={pendingWithdrawals}
            />
            <LogOutContainer>
              <Button
                variant="secondary"
                size="l"
                onPress={callLogout}
                qaLabel="logoutButton"
              >
                <Paragraph
                  fontFamily="medium"
                  qaLabel="logoutText"
                  color="red.500"
                >
                  Log Out
                </Paragraph>
              </Button>
            </LogOutContainer>
          </MainContainer>
        </AnimatedContent>
      )
  );
};

export default AccountMenu;

import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PROMO_LEVEL = "Promo Level",
  PROMO_OPTED_IN = "Promo Opted In",
  IS_FDTV = "Is FDTV",
  TRACK_COUNTRY = "Track Country"
}

export enum Module {
  TRACKS_AZ = "tracks_az",
  FAVORITES = "favorites",
  UPCOMING_RACES = "upcoming_races",
  TIL = "tracks_information",
  POOLS = "top_pools",
  TOP_TRACKS = "top_tracks",
  WINNERS = "previous_winners"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  CLICKED = "RACE_CELL_CLICKED"
}

type PromoOptedIn = "true" | "false" | "none";

export interface RaceCellData {
  type: MediatorEventType;
  payload: {
    module: Module;
    trackName: string;
    raceNumber: string;
    linkUrl: string;
    raceHasPromos: boolean;
    trackHasPromos: boolean;
    promoOptedIn: PromoOptedIn;
    isFDTV: boolean;
    trackCountry: string;
    tabActive?: string;
    activeFilters?: Record<string, string>[];
  };
}

import type { NullaryFn } from "@tvg/ts-types/Functional";
import type { Types } from "@amplitude/analytics-browser";
import type { Location } from "react-router";
import { TVG_PAGE_CONFIG } from "./constants";

export enum UserCustomProperties {
  USER_LOCATION = "User Location"
}

export type UserProperties = Record<UserCustomProperties, string>;

export interface PathnameHashAndSearch {
  pathname: string;
  hash: string;
  search: string;
}

export enum LoginStatusValues {
  LOGGED_IN = "logged_in",
  LOGGED_OUT = "logged_out"
}

export enum GlobalProperties {
  ANDROID_DISTRIBUTION = "Android Distribution Method",
  BALANCE = "Balance",
  FULL_URL = "Full URL",
  HOSTNAME = "Hostname",
  JURISDICTION = "Jurisdiction",
  LOGIN_STATUS = "Login Status",
  PAGE_NAME = "Page Name",
  PAGE_PATH = "Page Path",
  PAGE_TITLE = "Page Title",
  PRODUCT = "Product",
  SITE_PLATFORM = "Site Platform",
  SITE_VERSION = "Site Version"
}

export type GlobalPropertiesObject = Partial<
  Record<GlobalProperties, string | null>
>;

export type GetGlobalProperties = NullaryFn<GlobalPropertiesObject | undefined>;

export enum BaseEventProps {
  EVENT_CATEGORY = "Event Category",
  EVENT_LABEL = "Event Label",
  EVENT_VALUE = "Event Value",
  ERROR_TYPE = "Error Type",
  ACCOUNT_ID = "Account ID",
  ACTIVE_BETS = "Active Bets",
  SETTLED_BETS = "Settle Bets",
  BET_TYPE = "Bet Type",
  BET_ID = "Bet ID",
  BET_AMOUNT = "Bet Amount",
  CUSTOMER_STATUS = "Customer Status",
  FILTER_ACTIVE = "Filter Active",
  FILTERS_ACTIVE = "Filters Active",
  MENU = "Menu",
  MODULE = "Module",
  LINK_TEXT = "Link Text",
  LINK_URL = "Link URL",
  PAGE = "Page",
  PROMO_CODE = "Promo Code",
  PROMO_NAME = "Promo Name",
  RACE_NUMBER = "Race Number",
  RACE_NUMBER_SELECTION = "Selection Race Number",
  RACE_TRACK_SELECTION = "Selection Race Track",
  RACE_TYPE = "Race type",
  TRACK_NAME = "Track Name",
  REPEAT_BET = "Repeat Bet Number",
  RUNNER_AMOUNT = "Runner Amount",
  RUNNER_SELECTION_LIST = "Runner Selection List",
  REGISTRATION_STATUS = "Registration Status",
  RESIDENCE_STATE = "Residence State",
  SCREEN_NAME = "Screen Name",
  TAG = "Tag",
  A_TO_Z_TRACKS = "A To Z Tracks",
  FAV_TRACKS = "Fav Tracks",
  UP_NEXT_TRACKS = "Up Next Tracks",
  RUNNER_TAGS_VISIBLE = "Runner Tags Visible",
  RUNNER_NAME = "Runner Name",
  FILTER_VALUE = "Filter Value",
  FILTER_NAME = "Filter Name",
  TAB_ACTIVE = "Tab Active",
  TAB = "Tab",
  MTP = "MTP",
  SELECTION_SOURCE = "Selection Source",
  SELECT_RACE_TYPE = "Selection Race Type"
}

export interface GlobalPropertiesArgs {
  isLogged: boolean;
  balance: number;
  jurisdiction: string | undefined;
  location: Location;
  pageConfig: typeof TVG_PAGE_CONFIG;
  pageTitle: string;
}

export interface ExtraPropertiesArgs {
  prevPath: React.RefObject<string>;
}

export interface AmplitudeInitArgs {
  getGlobalProperties: GetGlobalProperties;
  pagesConfig?: typeof TVG_PAGE_CONFIG;
  options?: Types.BrowserOptions;
  extra?: ExtraPropertiesArgs;
}

export interface MediatorData<T> {
  type: string;
  payload: T;
}

import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  FILTER_TYPE = "Filter Type",
  PLACE_TYPE = "Place Type",
  SORT_TYPE = "Sort Type",
  SORT_NAME = "Sort Name",
  SORT_OPERATION = "Sort Operation"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  URP_RUNNER_SELECTION = "PPM_TVG5_RUNNER_SELECTION",
  RUNNER_SELECTION = "PPM_RUNNER_SELECTION",
  SELECT_ALL_RUNNERS = "PPM_SELECT_ALL_RUNNERS",
  RESET_ALL_RUNNERS = "PPM_RESET_ALL_RUNNERS",
  SELECT_BET_TYPE = "PPM_SELECT_BET_TYPE",
  SET_BET_AMOUNT = "PPM_SET_BET_AMOUNT",
  BET_TIMEOUT = "PPM_BET_TIMEOUT",
  BET_NOW = "PPM_BET_NOW",
  DELETE_BET_TICKET = "PPM_DELETE_BET_TICKET",
  OPEN_RACE_NAVIGATION = "PPM_OPEN_RACE_NAVIGATION",
  HANDICAP_STORE_PRODUCT = "PPM_HANDICAP_STORE_PRODUC",
  HANDICAP_STORE_SEE_ALL = "PPM_HANDICAP_STORE_SEE_ALL",
  RUNNER_MODIFIER_FILTER_APPLIED = "PROGRAM_PAGE_RUNNER_MODIFIER_FILTER_APPLIED",
  RUNNER_MODIFIER_SORT_APPLIED = "PROGRAM_PAGE_RUNNER_MODIFIER_SORT_APPLIED",
  WILL_PAYS_SELECT_OPTION = "PPM_WILL_PAYS_SELECT_OPTION",
  PROBABLES_SELECT_OPTION = "PPM_PROBABLES_SELECT_OPTION",
  PROGRAM_RUNNER_INFO_MODAL_OPENED = "PROGRAM_RUNNER_INFO_MODAL_OPENED",
  PROGRAM_RUNNER_INFO_MODAL_CLOSED = "PROGRAM_RUNNER_INFO_MODAL_CLOSED",
  FAVORITE_INTERACTION = "PPM_FAVORITE_INTERACTION",
  POOLS_AND_INFO_MODAL_OPENED = "POOLS_AND_INFO_MODAL_OPENED",
  POOLS_AND_INFO_TAB_OPENED = "POOLS_AND_INFO_TAB_OPENED",
  HANDICAPPING_TAB_OPENED = "HANDICAPPING_TAB_OPENED",
  RUNNER_TAGS_EXPANDED = "RUNNER_TAGS_EXPANDED",
  PROGRAM_FREE_PP_DOWNLOAD = "PROGRAM_FREE_PP_DOWNLOAD",
  DROPDOWN_OPEN = "PPM_DROPDOWN_OPEN",
  DROPDOWN_RACE_SELECTED = "PPM_DROPDOWN_RACE_SELECTED"
}

export interface ProgramPageData {
  type: MediatorEventType;
  payload: {
    add?: boolean;
    tag?: string;
    action?: string;
    selections?: string;
    betAmount?: string;
    runnerType?: string;
    label?: string;
    error?: string;
    trackName?: string;
    raceNumber?: string;
    betType?: string;
    primaryType?: string;
    secondaryType?: string;
    product?: string;
    destinationUrl?: string;
    race?: {
      raceNumber: string;
      track: {
        trackName: string;
      };
      type: {
        code: string;
      };
    };
    isCustomAmount?: boolean;
    module?: string;
    isFavorite?: boolean;
    filter?: string;
    sortOperation?: string;
    sortValue?: string;
    repeatBet?: string;
    runnerTagsVisible?: string;
    runnerName?: string;
  };
}

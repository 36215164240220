import styled, { css } from "styled-components";

export const SearchBarContainer = styled.div<{
  isFocused: boolean;
  hasWarning: boolean;
}>`
  ${({ isFocused, hasWarning, theme }) => css`
    border: 1px solid
      ${isFocused
        ? hasWarning
          ? theme.colorTokens.content.warning
          : theme.colors.blue_accent["500"]
        : theme.colors.grey["500"]};
  `}
  display: flex;
  padding: ${({ theme }) => theme.space["space-4"]};
  border-radius: ${({ theme }) => theme.radii.s};
  box-shadow: ${({ theme }) => theme.elevations.low};
`;

export const SearchIcon = styled.span`
  margin-right: 10px;
`;

export const TextInput = styled.input`
  width: 100%;

  &::placeholder {
    font-size: ${({ theme }) => theme.fonts.s};
    font-family: inherit;
  }
`;
